/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import ReactDOM from 'react-dom';
// import _ from "lodash";
// import Modal from 'react-responsive-modal';
// import axios from 'axios';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import Select from 'react-select';
import { 
    getDDMMMYYYYHHMMDefault,
	// getDDMMYYYYHHMMSS, 
	// getHyphenDDMMMYYYYHHMM,
	// getYYYYMMDD, 
	// getYYYYMMDDHHMMSS, 
	// getHyphenYYYYMMDDHHMMSS,
	// getHyphenYYYYMMDD, 
	// getHyphenDDMMYYYYHHMMSS, 
    // getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	// getHyphenDDMMYYYY,
    } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");
const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
var sdate = moment.parseZone().subtract(2, 'days').format('YYYY-MM-DD');
var edate = moment.parseZone().format('YYYY-MM-DD');

export default class MonthlyDistanceTravelled extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
            loadshow:'show-n',
            overly:"show-n",
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
            pdfRowData: [],
            pdfColumns: [],
            frameworkComponents: {
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"GPS EOD Analysis",
            sliderRouteTranslate:'',
            startDate:moment.parseZone().subtract(2, 'days').format('YYYY-MM-DD'),
            endDate:moment.parseZone().format('YYYY-MM-DD'),
            defaultStartDate:"",
            defaultEndDate:"",
            routeinfo:"",
            breaktruck:"",
            uniquetrucks : [],
			showTrucksList : "show-n",
            filteredData : [],
            tottalDistance : [],
            pinnedBottomRowData: [],
            pageType: 1,
            showDpk: {display:"none"},
            showMpk: {display:"none"},
            pageTitle: "Distance Travelled Report",
            tpttrucks:[],
            transporterTrucks:[],
			adaniCheck:0,
            truckNoList : [],
            trucksList: [],
            filterTransporters:[{"value":"ALL","label":"ALL"}],
            flTransporter: [],
            truck_no:{value:"",label:""}
        }; 
          
        this.onClickHideAll = this.onClickHideAll.bind(this);
	}
    cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
		try {
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		// eslint-disable-next-line no-unreachable
		catch (e) {
            console.log("e",e)
		}
    }
	
    componentDidMount(){
        loadDateTimeScript()
        let dpt = JSON.parse(localStorage.getItem("dept_code"))[1]
        // console.log("dpt",dpt)

        var params = {
            from_date: sdate +" 00:00:00",
            to_date: edate+" 23:59:59",
            dept_code : dpt
        }
        // console.log("params",params)
               
        $("#gpsstartdate").val(sdate);
        $("#gpsenddate").val(edate);

        // var stdate = moment.parseZone().subtract(2, 'days').format('DD-MM-YYYY HH:mm');
        // var etdate = moment.parseZone().format('DD-MM-YYYY HH:mm');

        var parameters = {
           dept_code : dpt
        }
        var trucksList = []
        redirectURL.post("/consignments/mlltrucks", parameters).then(response1 =>{
            let truckResponse = response1.data.records
              console.log("trucks",response1)

            truckResponse.map(e =>{
                trucksList.push(e)
               
            })           
        })
        var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        var filterTransporters = [];
        redirectURL.post("/consignments/getConsignmentsNewFilterKeysForSndTrucks", { dept_code: encode(dpt) })
			.then((resp) => {
                // console.log("resp",resp.data)		

				var transporters = resp.data.transportersList;
				//  console.log("transporters",transporters)
				filterTransporters.push({ value: 'ALL', label: 'All' });
				if (transporters.length > 0) {
					transporters.map(function (t) {
						filterTransporters.push({
							"value": t.transporter_code,
						    "label": t.transporter_name,
						});
					});
				}
        })
        this.setState({
            truckNoList: trucksList,
            filterTransporters: filterTransporters,
            dept_code : dpt
        })
        console.log("trucksList",trucksList)
 
        // if(dpt !== "LOG-PRT") this.onLoadData(params);
        this.onLoadData(params);
        // this.getUserGridState() 
	}
    getUserGridState =() =>{
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
          .then( async (resp) => {
            //   console.log("resp ", resp)
              this.setState({
                  usergridstate:resp.data
              });
               this.restoreGridStates();
          })
      }
    
    onLoadData = (parameters) =>{
        //  console.log("parameters",parameters)
        this.setState({
            loadshow:"show-m",
            overly:"show-m",
        })
		redirectURL.post("/consignments/monthlyDistanceTravelled",parameters)
		.then((response) => {
            // console.log(response,"response",response.data)
             var recs = JSON.parse(response.data.distance_travelled_report);
            //    console.log("recs", recs)
            if(recs.status == "Failure"){
                this.setState({
                    loadshow:"show-n",
                    overly:"show-n",
                    show: true,
                    basicType : 'danger',
                    basicTitle : 'Please Enter a Valid Input'
                })
            }else{
                // Bug Fixes for Total Distance Count Mismatch
                var filteredTrucks = new Set()
                var tpttrucks = this.state.truckNoList;
                tpttrucks.forEach(item => {
                    filteredTrucks.add(item.truck_no)
                })
                var tptunqrecords = []
                var tottalDistance = 0;

                if (tpttrucks.length > 0) {
                    recs.filter(f => {
                        if (filteredTrucks.has(f.truck_no)) {
                            tottalDistance = parseFloat(tottalDistance) + parseFloat(f.total_distance_travelled_km);
                            tptunqrecords.push(f)
                        }
                    }
                    );
                }
                else
                {
                    tptunqrecords = recs
                    if(tptunqrecords.length !== 0)
                    {
                        tptunqrecords.map((item) => {
                            tottalDistance = parseFloat(tottalDistance)+parseFloat(item.total_distance_travelled_km);
                            console.log("tottalDistance",tottalDistance)
                        })
                    }
                }
                var pinnedBottomRowData = {}
                pinnedBottomRowData['truck_no'] = "total";
                pinnedBottomRowData['total_distance_travelled_km'] = tottalDistance.toFixed(1);
                // console.log("pinnedBottomRowData",pinnedBottomRowData)
                $("#truck_no").val("")
                $("#gpsstartdate").val(sdate);
                $("#gpsenddate").val(edate);
                this.setState({
                    rowData: recs,
                    tottalDistance:[pinnedBottomRowData],
                    loadshow:"show-n",
                    overly:"show-n",
    
                });
            }
        })
        // this.componentDidMount();
    }

    onClickFilterData = () =>{
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        try
        {

            var truck_no=this.state.truck_no.value
            // console.log("truck_no",truck_no)
            // var startDate = $("#gpsstartdate").val();
            // var endDate = $("#gpsenddate").val();
            // console.log("startDate",startDate)
            // console.log("endDate",endDate)
    
            let transporter_codes = this.state.flTransporter;
            //  console.log("transporter_codes",transporter_codes)
            var parameters = {}
            const {startDate, endDate,dept_code} = this.state
            let chkStartDate = new Date(startDate)
            let chkEndDate = new Date(endDate)
            if(chkStartDate > chkEndDate){
                alert("To Date should be greater than From Date");      
                this.setState({
                    loadshow: "show-n",
                    overly:"show-n"
                });          
            }else{
                //"____-__-__ __:__"
                if(startDate != ''){
                    // console.log("startDate",startDate)
        
                    parameters.from_date = startDate+" 00:00:00";
                }
                else{
                    parameters.from_date = sdate+" 00:00:00";
                }
                if(endDate !== '' ){
                    // console.log("endDate",endDate)
        
                    parameters.to_date = endDate+" 23:59:59";
                }else{
                    parameters.to_date = edate+" 23:59:59";
                }
                if(truck_no.length !== 0 && truck_no !== "")  parameters.truck_no =  [truck_no]
                if(transporter_codes.length !==0) parameters.transporter_codes =  [transporter_codes.value]
                let deptCode = dept_code
                if(deptCode !== undefined) parameters.dept_code = deptCode
                //  console.log("parameters",parameters)
                this.onLoadData(parameters);
            }
        }
        catch(e){

        }
    }
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			bulkslide:'',
			sliderForceDestination:'',
            sliderBulkForceClose:"",
            showTrucksList : "show-n"
		});
		
    }

    newFilter = (filterType, selOption) => {
		console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
    onChangeTruckItem = (truck_no) => {
        // console.log("401")
        // console.log("truck_no",truck_no)
        this.setState({ truck_no },
            //   () => console.log(`Name  Option selected:`, this.state.truck_no)
        );

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }
    ooChangeTruck = () => {
		
        // var trucks = []
        // if (records.length > 0) {
        // 	records.map((item) => {
        // 		trucks.push({
        // 			value: item.truck_no,
        // 			label: item.truck_no
        // 		})
        // 	})
        // }
        //  console.log("trucks",trucks)
        // this.setState({
        // 	truckslist: trucks
        // });
        let items = [];     
        var truckNoList = this.state.truckNoList;
        //   console.log("truckNoList",truckNoList)
        if(truckNoList.length > 0)
        {
            truckNoList.map((item) => {
                if(item.vin_number != "" && item.vin_number != undefined)
                {

                    items.push({
                        value:item.truck_no,
                        label:item.vin_number
                    })
                }
                else{

                    items.push({
                        value:item.truck_no,
                        label:item.truck_no
                    })
                }
          
            // console.log("items",items)  
    })
        }
        // consigners.map((item) =>{
        // 	items.push({value:item.plant_code,label:item.plant_name}); 
        // });
        
        return items;
    

    }

   
    onChangeSourceItem(refno){
		this.setState(
			{ refno },
			// () => console.log(`Option selected:`, this.state.refno)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
   
    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
	 }

    onOpenModal = () => {
		this.setState({ open: true });
	};
	
	onCloseModal = () => {
        this.gridApi.deselectAll();
	    this.setState({ open: false });
    };
    
	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		if(this.state.endDate !=undefined && this.state.endDate !=""){
			// console.log(startdate,"startdate")
			// console.log(this.state.endDate,"endDate")
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if(startTime > endTime){
				this.setState({
					startDate:""
				});
				alert("To Date should be greater than From Date");
			}else{ 	
				this.setState({
					startDate:startdate
				});
			}
		}
		else{
			this.setState({
				startDate:startdate
			});
		}
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
		
		if(this.state.endDate !=undefined && this.state.endDate !=""){
			// console.log(this.state.startDate,"startdate")
			// console.log(edate,"endDate")
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if(startTime > endTime){
				this.setState({
					endDate:""
				});
				alert("To Date should be greater than From Date");
			}
            else{ 
				this.setState({
					endDate:edate
				});
			}
		}
		else{
			this.setState({
				endDate:edate
			});
		}
    }
    

    initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        
        dataset = dataset.slice(0,100);
        var tpttrucks = this.state.tpttrucks;
        console.log("tpttrucks",tpttrucks)
        var tptunqrecords = []
        if(tpttrucks.length > 0)
        {
            dataset.filter(f =>
                tpttrucks.filter((d )=> {
                    if(d.truck_no == f){									
                        tptunqrecords.push(f)
                    }
                })
            );
        }
        else
        {
            tptunqrecords = dataset
        }
        this.setState({
            filteredData : tptunqrecords,
            showTrucksList : "show-m",
        })
    }
    handlerForm = (event) => {
    	//console.log("Select", event.target.value);
    	// let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        if(tpttrucks.length > 0)
        {
            dataset.filter(f =>
                tpttrucks.filter((d )=> {
                    if(d.truck_no == f){									
                        tptunqrecords.push(f)
                    }
                })
            );
        }
        else
        {
            tptunqrecords = dataset
        }
        var filteredResult = tptunqrecords.filter(function(e){
            if(e != "" && e != null)
            {
                return e.toString().toLowerCase().includes($("#truck_no").val().toLocaleLowerCase());
            }
            
        });
        filteredResult = filteredResult.slice(0,100);
        this.setState({
            filteredData : filteredResult,
            showTrucksList : "show-m",
        })
    }
    hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }
    onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();		
		let screenpage = 'Distance Travelled Report';
		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}


	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

    
    render(){
        var listData = [] 
        this.state.filteredData.map((e) =>{
            listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        // var pageType = this.state.pageType;
        // if(pageType == 1)
        // {
        //     var hidemonthly = false;
        // }
        // else
        // {
        //     hidemonthly = true;
        // }
        var adaniCheck = this.state.adaniCheck;
		var tkName = "Vin No";
		if(adaniCheck == 1)
		{
			tkName = "Shuttle No";
		}
        var columnwithDefs = [
			
            {
                headerName:tkName,
                field:"vin_number",
                width:180,
                valueGetter:function(params){
                    try {
                     
                        if(params.data.showbreaks == "total")
                        {
                            return "";
                        }
                        else
                        {
                            if(params.data.vin_number != "" && params.data.vin_number != undefined)
                            {
                                return params.data.vin_number;
                            }
                            else{
                                return params.data.truck_no;
                            }
                        }   
                    } catch (error) {
                        
                    }
                }
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:160
            },
            {
                headerName:"Distance Travelled (KM)",
                field:"total_distance_travelled_km",
                width:180,
                valueGetter:function(params){
                    try{
                        return params.data.total_distance_travelled_km;
                    }
                    catch(e){
                        return "";
                    }                    
                }
            },
            {
                headerName:"Break Duration",
                field:"total_break_time_mins",
                width:180,
                valueGetter:function(params){
                    try{
                        return secondsToDhms(parseInt(params.data.total_break_time_mins)*60);
                    }
                    catch(e){
                        return "";
                    }                    
                }
            },
            
            {
                headerName:"Travel Duration",
                field:"total_travel_time_mins",
                width:180,
                valueGetter:function(params){
                    try{
                        return secondsToDhms(parseInt(params.data.total_travel_time_mins)*60);
                    }
                    catch(e){
                        return "";
                    }                    
                }
            },
            {
                headerName:"From Date",
                field:"from_date",
                width:180,
                valueGetter:function(params){
                    try{
                        // console.log("params.data.from_date",params.data.from_date)
                        if(params.data.from_date != "" && params.data.from_date != undefined)
                        {
                            return getDDMMMYYYYHHMMDefault(params.data.from_date);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }                    
                }
            },
            {
                headerName:"To Date",
                field:"end_date",
                width:180,
                valueGetter:function(params){
                    try{
                        if(params.data.end_date != "" && params.data.end_date != undefined)
                        {
                            return getDDMMMYYYYHHMMDefault(params.data.end_date);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }                    
                }
            },
           
		]
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
                           <div className="mb-15p fbold f20">  
                                <h5>
				       				<i className="icofont icofont-truck-alt cus-i"></i> <span>{this.state.pageTitle}  </span>
                                    {/* <span className="layoutbtns pull-right">
                                         <button className="float-right custom-btn white btn-danger"
                                         style={{marginRight: "10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.onClickSaveGridState.bind(this)}>
                                             <i className="icofont icofont-save"></i> Save Grid Layout
                                         </button>
                                        <button className="float-right custom-btn white btn-info" style={{marginRight:"10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.resetState.bind(this)}>
                                             <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span> */}
								</h5>
                            </div>    
            	<div className="row">
                   
            		<div className="col-xl-12 col-lg-12">
                    	<div className="card">
				   			<div className="card-body row">
                                <div className="row col-xl-12 col-lg-12">
                                    <div style={{top:"65px"}} className={"trucks-dropdown "+this.state.showTrucksList}>
                                        <ul>
                                            {listData}
                                        </ul>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 form-group">
                                        <label>{(this.state.adaniCheck == 1) ? "Shuttle No" : "Vin No"}</label>
                                        <Select
                                        name="truck_no" 
                                        // id="inputTruck" 
                                        id="truck_no"
                                        value={this.state.truck_no}
                                        autoComplete="off" 
                                        className="border-radius-0"
                                        onChange={this.onChangeTruckItem}
                                        options={this.ooChangeTruck()}  />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
										<label>Transporter</label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedTransporter"
											style={{ borderRadius: "0px" }}
											value={this.state.flTransporter}
											onChange={this.newFilter.bind(this, "flTransporter")}
											options={this.state.filterTransporters}
										/>
									</div>

                                    <div className="col-xl-2 col-lg-2 form-group">
                                        <label>From Date</label>
                                        {/* <input 
                                            type="text" 
                                            id="gpsstartdate"
                                            name="gpsstartdate"
                                            className="form-control"
                                        /> */}
                                        <Datetime
                                            value={this.state.startDate}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off',readOnly: true }}
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            name="startDate"
                                            onChange={this.handlerStartDateTime} />
                                    </div>

                                    <div className="col-xl-2 col-lg-2 form-group">
                                        <label>To Date</label>
                                        {/* <input 
                                            type="text" 
                                            id="gpsenddate"
                                            name="gpsenddate"
                                            className="form-control"
                                        /> */}
                                        <Datetime
												value={this.state.endDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off',readOnly: true }}
												dateFormat="YYYY-MM-DD"
                                                timeFormat={false}
												onChange={this.handlerEndDateTime} />
                                    </div>
                                    
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <button type="button" className="btn btn-success" style={{marginTop:"29px"}} onClick={this.onClickFilterData}>Submit</button>
                                    </div>
                                    
                                </div>
                                <div className={"row col-xl-12 col-lg-12 "}>
                                   
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
											detailCellRendererParams={this.state.detailCellRendererParams}
                                            masterDetail={true}
                                            pinnedBottomRowData={this.state.tottalDistance}
                                            gridOptions={{
                                                context: { componentParent: this },
                                                getRowStyle: function (params) {
                                                    if (params.node.rowPinned) {
                                                      return { 'font-weight': 'bold','font-size':'16px' };
                                                    }
                                                  },
                                            }}
                                        />

                                    </div>
                                </div>
                                
                            </div>

				   		</div>	
				   	</div>
				 </div>

				
                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll}></div>
            
            </div>
              
        );
    }
}
window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#gpsstartdate').datetimepicker({
		// mask:'39-19-9999 29:59',
        // format:'d-m-Y H:i'
        mask:'9999-19-39 29:59',
        format:'yy-m-d H:i'
	});
	$('#gpsenddate').datetimepicker({
		// mask:'39-19-9999 29:59',
		// format:'d-m-Y H:i'
        mask:'9999-19-39 29:59',
        format:'yy-m-d H:i'
    });

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

    $("#report_date").datepicker( {
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "mm-yy",
        onClose: function (dateText, inst) {

            //Get the selected month value
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();

            //Get the selected year value
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();

            //set month value to the textbox
            $(this).datepicker('setDate', new Date(year, month, 1));
        },
        beforeShow: function(input, inst) {
            $('#ui-datepicker-div').addClass("report_date");
        }
    });

	$('#gpsstartdate').datetimepicker({
		// mask:'39-19-9999 29:59',
		mask:'9999-19-39 29:59',
        // format:'d-m-Y H:i'
        format:'yy-m-d H:i'
	});
	$('#gpsenddate').datetimepicker({
		// mask:'39-19-9999 29:59',
		// format:'d-m-Y H:i'
        mask:'9999-19-39 29:59',
        format:'yy-m-d H:i'

	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}

function secondsToString(seconds)
{
	seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

function timeConvert(n) {
	var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}   
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function minutesToDHM(n){
    var num = n;
    var d = Math.floor(num/(60*24));
    var h = Math.floor((num %(60*24))/60);
    var m = Math.floor((num %(60*24))%60);

    var dDisplay = d >= 0 ? d + (d === 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h === 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m === 1 ? " Mins " : " Mins ") : "";
    return dDisplay+ hDisplay + mDisplay
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }
  
  function arrayMax(arr) {
	return arr.reduce(function (p, v) {
	  return ( p > v ? p : v );
	});
  }
  $(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#truck_no").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#truck_no");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})
